<template>
  <b-card>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Account</span>
    </template>
    <invite-account-tab
      class="mt-2 pt-75"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import InviteAccountTab from './InviteAccount.vue'

export default {
  components: {
    BCard,

    InviteAccountTab,
  },
}
</script>

<style>

</style>
